import React from "react";
import "../HelpButtonCSS/HelpButtonDatasetTitleCSS.css";

const HelpButtonDatasetTitle = () => {
	return (
		<div className="help1">
			<div className="question1">Ⓘ</div>

			<div className="popup1">
				<h3>Dataset Title</h3>
				<p>
					Human-readable name of the asset. Should be in plain English and
					include sufficient detail to facilitate search and discovery.&nbsp;
					<a
						target="_blank"
						rel="noreferrer"
						href={"https://resources.data.gov/resources/dcat-us/#title"}
					>
						Click Here
					</a>
					&nbsp;to read more about the Dataset Title.
				</p>
				{/* <p>

				</p>
				<p>

				</p> */}
				{/* <br />
				<br />
				<div className="tell-me">
					<p>Tell me more!</p>
					<div className="popup2">
						<h4>Sub-levels</h4>
						<p className="sub-levels">
							<strong>a</strong> = you consistently reach this level
						</p>
						<p className="sub-levels">
							<strong>b</strong> = you reach this level most of the time
						</p>
						<p className="sub-levels">
							<strong>c</strong> = you sometimes reach this level
						</p>
						<p>
							<a href="#" target="_blank">
								Click here
							</a>{" "}
							for more detailed Computing &amp; IT level descriptions.
						</p>
						<p>
							<em>
								Level descriptions for individual units will be available soon.
							</em>
						</p>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default HelpButtonDatasetTitle;
