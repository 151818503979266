import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import BureauCodes from "../../data/BureauCodes.json";

// This is the component for tag/keyword input field
function BureauCode() {
	return (
		<>
			<option>-- No License --</option>
			{BureauCodes.map((bureauCode, index) => {
				const aCode = ("000" + bureauCode["Agency Code"]).substr(-3);
				const bCode = ("000" + bureauCode["Bureau Code"]).substr(-3);
				const code = aCode + ":" + bCode;
				return (
					<option key={index} value={code}>
						{bureauCode["Bureau Name"]}
					</option>
				);
			})}
		</>
	);
}
export default BureauCode;
