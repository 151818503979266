import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import Frequency from "../../data/Frequency.json";

// This is the component for tag/keyword input field
function UpdateFrequency() {
	return (
		<>
			<option>-- No License --</option>
			{Frequency.map((frequency) => {
				return (
					<option key={frequency.key} value={frequency.key}>
						{frequency.value}
					</option>
				);
			})}
		</>
	);
}
export default UpdateFrequency;
