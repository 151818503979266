import React from "react";
import "../HelpButtonCSS/HelpButtonProgramCodeCSS.css";

const HelpButtonProgramCode = () => {
	return (
		<div className="help10">
			<div className="question10">Ⓘ</div>

			<div className="popup10">
				<h3>Row Label</h3>
				<p>
				Federal agencies, list the primary program related to this data asset, from the Federal Program Inventory. Use the format of 015:001.&nbsp;
					<a
						target="_blank"
						rel="noreferrer"
						href={"https://resources.data.gov/resources/dcat-us/#programCode"}
					>
						Click Here
					</a>
					&nbsp;to read more about Program Code.
				</p>
				{/* <p>

				</p>
				<p>

				</p> */}
				{/* <br />
				<br />
				<div className="tell-me">
					<p>Tell me more!</p>
					<div className="popup2">
						<h4>Sub-levels</h4>
						<p className="sub-levels">
							<strong>a</strong> = you consistently reach this level
						</p>
						<p className="sub-levels">
							<strong>b</strong> = you reach this level most of the time
						</p>
						<p className="sub-levels">
							<strong>c</strong> = you sometimes reach this level
						</p>
						<p>
							<a href="#" target="_blank">
								Click here
							</a>{" "}
							for more detailed Computing &amp; IT level descriptions.
						</p>
						<p>
							<em>
								Level descriptions for individual units will be available soon.
							</em>
						</p>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default HelpButtonProgramCode;
