import * as React from "react";
import "@pathofdev/react-tag-input/build/index.css";
import ReactTagInput from "@pathofdev/react-tag-input";

// This is the component for tag/keyword input field
function MyTagInput({ onhandleChangeTag, value }) {
	const [tags, setTags] = React.useState([]);

	React.useEffect(() => {
		// Update the document title using the browser API
		if (tags != "") onhandleChangeTag(tags);
	});
	React.useEffect(() => {
		setTags([...value]);
	}, []);

	return (
		<ReactTagInput
			tags={tags}
			// placeholder="Type Tag/Keyword and Press Enter"
			// type = "file"
			onChange={(newTags) => {
				let temp = newTags[newTags.length - 1];
				let k = newTags.length - 1;
				for (let i = 0; i < k; i++) {
					if (newTags[i].includes(temp)) return;
				}
				setTags(newTags);
			}}
		/>
	);
}
export default MyTagInput;
