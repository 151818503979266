import { Link, useHistory } from "react-router-dom";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import React from "react";
import axios from "axios";

// This is the main file of the app
class App extends React.Component {
	//constructor to initiate app component
	constructor(props) {
		super(props);
		this.state = { scrapeUrl: "" };

		this.handleChange = this.handleChange.bind(this);
		this.scrapeClick = this.scrapeClick.bind(this);
	}

	//Event when the input filed is changed
	handleChange(event) {
		this.setState({ scrapeUrl: event.target.value });
	}

	//Event when click the scrape button
	scrapeClick(e) {
		e.preventDefault();
		console.log(this.state.scrapeUrl);
		if (this.state.scrapeUrl != "") {
			axios
				.get(this.state.scrapeUrl)
				.then((res) => {
					this.props.history.push({
						pathname: "/showform",
						state: { scrapeData: res.data, id: this.state.scrapeUrl },
					});
				})
				.catch((err) => {
					alert(err);
				});
		} else {
			alert("Please type url");
		}
	}

	render() {
		return (
			<div className="App container text-center mt-5 url-input">
				<h4>
					Enter <code>JSON</code> URL to Scrape
				</h4>
				<Form.Control
					type="text"
					placeholder="Enter JSON URL Here"
					name="name"
					className="mt-3"
					style={{
						width: "50%",
						margin: "auto",
						textAlign: "center",
					}}
					value={this.state.scrapeUrl}
					onChange={this.handleChange}
				/>
				<Button variant="primary" className="mt-3" onClick={this.scrapeClick}>
					Scrape JSON
				</Button>
			</div>
		);
	}
}

export default App;
