import "./ShowForm.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, InputGroup, FormControl } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFireExtinguisher, faLock } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import MyTagInput from "../../components/MyTagInput/MyTagInput";
import FileUpload from "../../components/FileUpload/FileUpload";
import {
	HelpButtonDatasetTitle,
	HelpButtonBriefDescription,
	HelpButtonRowLabel,
	HelpButtonTagsKeywords,
	HelpButtonPublisher,
	HelpButtonOrganization,
	HelpButtonContactName,
	HelpButtonContactEmail,
	HelpButtonBureauCode,
	HelpButtonProgramCode,
	HelpButtonPublicAccessLevel,
	HelpButtonLicense,
} from "../../components/HelpButton/HelpButtonJS";

import ProgramCode from "../../components/ProgramCode/ProgramCode";
import BureauCode from "../../components/BureauCode/BureauCode";
import UpdateFrequency from "../../components/UpdateFrequency/UpdateFrequency";
import Frequency from "../../data/Frequency.json";

// This is the main file of the app
function ShowForm(props) {
	//return "" if there is no value
	const getData = (data) => {
		if (data == undefined) {
			return "";
		} else {
			return data;
		}
	};

	const location = useLocation();
	const scrapeData = location.state.scrapeData;
	// const scrapeData = Temp1;
	// const scrapeData = Temp2;
	// console.log(scrapeData);
	// Total json
	const initialTotalData = Object.freeze({
		id: getData(location.state.id),
		name: getData(scrapeData.name),
		attribution: getData(scrapeData.attribution),
		attributionLink: getData(scrapeData.attributionLink),
		category: getData(scrapeData.category),
		createdAt: "",
		viewLastModified: "",
		description: getData(scrapeData.description),
		license: "",
		metadata: "",
		privateMetadata: "",
		tags: getData(scrapeData.tags),
	});
	const [totalData, updateTotalData] = useState(initialTotalData);

	//license
	const initialLicenseData = Object.freeze({
		name: scrapeData.license ? getData(scrapeData.license.name) : "",
		logoUrl: "",
		termsLink: "",
	});
	const [licenseData, updateLicenseData] = useState(initialLicenseData);

	// PrivateMetadata json
	const initialPrivateMetaData = Object.freeze({
		contactEmail: scrapeData.privateMetaData
			? getData(scrapeData.privateMetaData.contactEmail)
			: "",
	});
	const [privateMetaData, updatePrivateMetaData] = useState(
		initialPrivateMetaData
	);

	// Metadata json
	const initialMetaData = Object.freeze({
		attachments: scrapeData.metadata.attachments
			? getData(scrapeData.metadata.attachments)
			: [],
		custom_fields: "",
		rowLabel: getData(scrapeData.metadata.rowLabel),
	});
	const [metaData, updateMetaData] = useState(initialMetaData);
	// console.log(metaData.attachments);

	// Attachments json
	// const initialAttachments = Object.freeze([{
	// 	filename: "",
	// 	assetId: "",
	// 	name: ""
	// }]);
	// if(scrapeData.metadata.attachments.length > 0){
	// 	const initialAttachments = getData(scrapeData.metadata.attachments);
	// }
	// const [attachments, updateAttachments] = useState(initialAttachments);
	// console.log(attachments);

	// Custom_fields json
	const initialCustomFields = Object.freeze({
		"Secretary Priorities": "",
		"Common Core": "",
	});
	const [customFields, updateCustomFields] = useState(initialCustomFields);

	// Secretary priorities json
	const initialSecretaryPriorities = Object.freeze({
		"Secretary Priorities": scrapeData.metadata.custom_fields[
			"Secretary Priorities"
		]
			? getData(
					scrapeData.metadata.custom_fields["Secretary Priorities"][
						"Secretary Priorities"
					]
			  )
			: "",
	});
	const [secretaryPriorities, updateSecretaryPriorities] = useState(
		initialSecretaryPriorities
	);

	// Common core json
	//Init Update Frequency value according to value type
	const initFrequency = (data) => {
		var returnValue = "";
		var keyList = [];
		var valueList = [];

		Frequency.map((frequency) => {
			keyList.push(frequency.key);
			valueList.push(frequency.value);
		});

		if (valueList.includes(data)) {
			for (let i = 0; i < Frequency.length; i++) {
				if (data == Frequency[i].value) {
					returnValue = Frequency[i].key;
				}
			}
		} else {
			returnValue = data;
		}

		return returnValue;
	};

	const initialCommonCore = Object.freeze({
		Homepage: getData(
			scrapeData.metadata.custom_fields["Common Core"]["Homepage"]
		),
		"Primary IT Investment UII": getData(
			scrapeData.metadata.custom_fields["Common Core"][
				"Primary IT Investment UII"
			]
		),
		"Contact Name": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Contact Name"]
		),
		"Update Frequency": initFrequency(
			scrapeData.metadata.custom_fields["Common Core"]["Update Frequency"]
		),
		"Temporal Applicability": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Temporal Applicability"]
		),
		License: getData(
			scrapeData.metadata.custom_fields["Common Core"]["License"]
		),
		"Program Code": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Program Code"]
		),
		Publisher: getData(
			scrapeData.metadata.custom_fields["Common Core"]["Publisher"]
		),
		"System of Records": getData(
			scrapeData.metadata.custom_fields["Common Core"]["System of Records"]
		),
		"Is Quality Data": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Is Quality Data"]
		),
		"Bureau Code": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Bureau Code"]
		),
		"Contact Email": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Contact Email"]
		),
		Language: getData(
			scrapeData.metadata.custom_fields["Common Core"]["Language"]
		),
		"Described By": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Described By"]
		),
		References: getData(
			scrapeData.metadata.custom_fields["Common Core"]["References"]
		),
		Issued: getData(scrapeData.metadata.custom_fields["Common Core"]["Issued"]),
		Theme: getData(scrapeData.metadata.custom_fields["Common Core"]["Theme"]),
		Collection: getData(
			scrapeData.metadata.custom_fields["Common Core"]["Collection"]
		),
		"Geographic Coverage": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Geographic Coverage"]
		),
		"Access Level Comment": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Access Level Comment"]
		),
		"VA Organization": getData(
			scrapeData.metadata.custom_fields["Common Core"]["VA Organization"]
		),
		"Public Access Level": getData(
			scrapeData.metadata.custom_fields["Common Core"]["Public Access Level"]
		),
	});
	const [commonCore, updateCommonCore] = useState(initialCommonCore);
	// States that are used in the form
	// const [fileData, setFileData] = useState([]);

	const [fileData, setFileData] = useState(null);
	const [assetIdData, setAssetIdData] = useState(null);
	const [tagData, setTagData] = useState("");
	const [validated, setValidated] = useState(false);
	const [requiredTag, setRequiredTag] = useState("");

	const initialFileData = [];
	const initialAssetIdList = [];

	const [attributionLinkStyle, setAttributionLinkStyle] = useState({});
	const [describedLinkStyle, setDescribedLinkStyle] = useState({});
	const [systemLinkStyle, setSystemLinkStyle] = useState({});
	const [homepageLinkStyle, setHomepageLinkStyle] = useState({});
	const [temporalStyle, setTemporalStyle] = useState({});
	const [languageStyle, setLanguageStyle] = useState({});

	const [temporalClassName, setTemporalClassName] =
		useState("red-font blockShow");
	const [languageClassName, setLanguageClassName] =
		useState("red-font blockShow");

	// Validation check variable
	var isTemporalValid = false;
	var isLanguageValid = false;
	var isURLValid = false;

	useEffect(() => {
		if (scrapeData.metadata.attachments) {
			for (let i = 0; i < scrapeData.metadata.attachments.length; i++) {
				initialFileData.push(scrapeData.metadata.attachments[i].filename);
				initialAssetIdList.push(scrapeData.metadata.attachments[i].assetId);
			}
		}
		setFileData(initialFileData);
		setAssetIdData(initialAssetIdList);
	}, []);

	//update total data
	const handleTotalChange = (e) => {
		updateTotalData({
			...totalData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	//update license data
	const handleLicenseChange = (e) => {
		updateLicenseData({
			...licenseData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	//update private meta data
	const handlePrivateMetaDataChange = (e) => {
		updatePrivateMetaData({
			...privateMetaData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	//update meta data
	const handleMetaDataChange = (e) => {
		updateMetaData({
			...metaData,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	//update secretary priorities
	const handleSecretaryChange = (e) => {
		updateSecretaryPriorities({
			...secretaryPriorities,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};
	//update common core
	const handleCommonCoreChange = (e) => {
		updateCommonCore({
			...commonCore,
			// Trimming any whitespace
			[e.target.name]: e.target.value,
		});
	};

	//check if the url return 200 status
	const checkUrl = (url, name) => {
		const headers = {
			"Content-Type": "application/json",
			"Access-Control-Allow-Origin": "*",
		};

		axios
			.get(url, { headers: headers })
			.then((res) => {
				switch (name) {
					case "Source Link":
						setAttributionLinkStyle({
							borderColor: "#198754",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
					case "Described By":
						setDescribedLinkStyle({
							borderColor: "#198754",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
					case "System of Records":
						setSystemLinkStyle({
							borderColor: "#198754",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
					case "Homepage":
						setHomepageLinkStyle({
							borderColor: "#198754",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
				}
				console.log(res);
				isURLValid = true;
				toast(name + " URL is working", {
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
				});
			})
			.catch((err) => {
				switch (name) {
					case "Source Link":
						setAttributionLinkStyle({
							borderColor: "#dc3545",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
					case "Described By":
						setDescribedLinkStyle({
							borderColor: "#dc3545",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
					case "System of Records":
						setSystemLinkStyle({
							borderColor: "#dc3545",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
					case "Homepage":
						setHomepageLinkStyle({
							borderColor: "#dc3545",
							backgroundImage: "url('http://www.w3.org/2000/svg')",
						});
						break;
				}
				isURLValid = false;
				toast(name + " URL - " + err.message, {
					type: toast.TYPE.ERROR,
					autoClose: false,
				});
			});
	};

	// When click the save button of the form
	const handleSubmit = (event) => {
		//List for url fields
		var urlList = [
			totalData.attributionLink,
			commonCore["Described By"],
			commonCore["System of Records"],
			commonCore["Homepage"],
		];
		//Name for url fields
		var nameList = [
			"Source Link",
			"Described By",
			"System of Records",
			"Homepage",
		];
		//Split urls by comma of References field
		var references = commonCore["References"];
		references.split(",").map(function (reference) {
			urlList.push(reference);
			nameList.push("References");
		});
		//Call checkUrl function with urls
		const isEmpty = (currentValue) => (currentValue == "" ? true : false);

		if (urlList.every(isEmpty)) {
			isURLValid = true;
		} else {
			for (let i = 0; i < urlList.length; i++) {
				if (urlList[i] != "") checkUrl(urlList[i], nameList[i]);
			}
		}

		var fileArray = [];
		for (let i = 0; i < fileData.length; i++) {
			var fileJson = {
				filename: fileData[i],
				assetId: assetIdData[i],
				name: fileData[i],
			};
			fileArray.push(fileJson);
		}

		// Add validation to Temporal Applicability field of Common Core data
		var splitTemporal = commonCore["Temporal Applicability"].split("/");

		if (commonCore["Temporal Applicability"] != "") {
			if (splitTemporal.length != 2) {
				setTemporalStyle({
					borderColor: "#dc3545",
					backgroundImage: "url('http://www.w3.org/2000/svg')",
				});
				setTemporalClassName("red-font");
			} else {
				if (
					!/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})Z$/.test(splitTemporal[0]) ||
					!/^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})Z$/.test(splitTemporal[1])
				) {
					setTemporalStyle({
						borderColor: "#dc3545",
						backgroundImage: "url('http://www.w3.org/2000/svg')",
					});
					setTemporalClassName("red-font");
				} else {
					setTemporalStyle({
						borderColor: "#198754",
						backgroundImage: "url('http://www.w3.org/2000/svg')",
					});
					setTemporalClassName("red-font blockShow");
					isTemporalValid = true;
				}
			}
		} else isTemporalValid = true;

		// Add validation to Language field of Common Core data
		var languageArray;
		const isLangValid = (currentValue) =>
			/^(((([A-Za-z]{2,3}(-([A-Za-z]{3}(-[A-Za-z]{3}){0,2}))?)|[A-Za-z]{4}|[A-Za-z]{5,8})(-([A-Za-z]{4}))?(-([A-Za-z]{2}|[0-9]{3}))?(-([A-Za-z0-9]{5,8}|[0-9][A-Za-z0-9]{3}))*(-([0-9A-WY-Za-wy-z](-[A-Za-z0-9]{2,8})+))*(-(x(-[A-Za-z0-9]{1,8})+))?)|(x(-[A-Za-z0-9]{1,8})+)|((en-GB-oed|i-ami|i-bnn|i-default|i-enochian|i-hak|i-klingon|i-lux|i-mingo|i-navajo|i-pwn|i-tao|i-tay|i-tsu|sgn-BE-FR|sgn-BE-NL|sgn-CH-DE)|(art-lojban|cel-gaulish|no-bok|no-nyn|zh-guoyu|zh-hakka|zh-min|zh-min-nan|zh-xiang)))$/.test(
				currentValue
			);
		if (commonCore["Language"] != "") {
			languageArray = commonCore["Language"].split(",");

			if (!languageArray.every(isLangValid)) {
				setLanguageStyle({
					borderColor: "#dc3545",
					backgroundImage: "url('http://www.w3.org/2000/svg')",
				});
				setLanguageClassName("red-font");
			} else {
				setLanguageStyle({
					borderColor: "#198754",
					backgroundImage: "url('http://www.w3.org/2000/svg')",
				});
				setLanguageClassName("red-font blockShow");
				isLanguageValid = true;
				commonCore.Language = languageArray;
				updateCommonCore({
					...commonCore,
				});
			}
		} else isLanguageValid = true;

		if (isTemporalValid && isLanguageValid && isURLValid) {
			//Make the jsonData according to form's data
			var jsonData = {};
			jsonData["id"] = totalData.id;
			jsonData["name"] = totalData.name;
			jsonData["attribution"] = totalData.attribution;
			jsonData["attributionLink"] = totalData.attributionLink;
			jsonData["category"] = totalData.category;
			jsonData["createdAt"] = Date.now();
			jsonData["viewLastModified"] = Date.now();
			jsonData["description"] = totalData.description;
			jsonData["license"] = licenseData;

			var jsonMetadata = {};
			// jsonMetadata["attachments"] = metaData.attachments;
			jsonMetadata["attachments"] = fileArray;
			var jsonCustomFields = {};
			jsonCustomFields["Secretary Priorities"] = secretaryPriorities;
			jsonCustomFields["Common Core"] = commonCore;
			jsonMetadata["custom_fields"] = jsonCustomFields;
			jsonMetadata["rowLabel"] = metaData.rowLabel;

			jsonData["metadata"] = jsonMetadata;
			jsonData["privateMetadata"] = privateMetaData;
			jsonData["tags"] = tagData;

			// console.log(jsonData);

			//Call the backend api to save form
			axios
				.post("/api/form/", jsonData)
				.then((res) => {
					toast("Form is saved successfully", {
						type: toast.TYPE.SUCCESS,
						autoClose: 3000,
					});
				})
				.catch((err) => {
					toast(err.message, { type: toast.TYPE.ERROR, autoClose: 3000 });
				});

			downloadFile({
				data: JSON.stringify(jsonData),
				fileName: "form.json",
				fileType: "text/json",
			});
		}

		const form = event.currentTarget;
		// Show required field message of tag input
		if (tagData === "") {
			setRequiredTag("This field is required");
		} else setRequiredTag("");

		// Check if all the required fields are filled
		if (form.checkValidity() === false) {
			event.preventDefault();
			event.stopPropagation();
		} else {
			// console.log(formData);
			event.preventDefault();
			event.stopPropagation();
		}
		setValidated(true);
	};

	// Download the data as a file
	const downloadFile = ({ data, fileName, fileType }) => {
		const blob = new Blob([data], { type: fileType });

		const a = document.createElement("a");
		a.download = fileName;
		a.href = window.URL.createObjectURL(blob);
		const clickEvt = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: true,
		});
		a.dispatchEvent(clickEvt);
		a.remove();
	};
	// Create a reference to the hidden file input element
	const hiddenFileInput = React.useRef(null);
	const handleFileUpload = (temp) => {};
	// Programmatically click the hidden file input element
	// when the Button component is clicked
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};
	// Call a function (passed as a prop from the parent component)
	// to handle the user-selected file
	const fileChange = (event) => {
		const fileUploaded = event.target.files[0];
	};

	return (
		<div className="ShowForm">
			<Form
				className="container mt-5"
				noValidate
				validated={validated}
				onSubmit={handleSubmit}
			>
				{/* Title and Description section */}
				<h1>Meta Checker</h1>
				<hr></hr>
				<h3>Title and Description</h3>
				<p>Make your title and description as clear and simple as possible.</p>
				{/* -------DataTitle------  */}
				<Form.Group
					className="mb-3 titleDescription"
					controlId="formBasicEmail"
				>
					{/* Label for title */}
					<Form.Label>
						Dataset Title <span className="text-danger">*</span>
						<HelpButtonDatasetTitle />
					</Form.Label>
					{/* text field for title */}
					<Form.Control
						type="text"
						placeholder="Dataset Title"
						name="name"
						onChange={handleTotalChange}
						value={totalData.name}
						required
					/>
					<small className="red-font">This field is required</small>
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* ----DataTitle------  */}
				{/* ------Brief Decription-----  */}
				<Form.Group
					className="mb-3 briefDescription"
					controlId="formBasicEmail"
				>
					{/* Label for description */}
					<Form.Label>
						Brief Description <span className="text-danger">*</span>
						<HelpButtonBriefDescription />
					</Form.Label>
					{/* Text area for description */}
					<Form.Control
						as="textarea"
						rows={5}
						placeholder="Enter a description"
						name="description"
						onChange={handleTotalChange}
						value={totalData.description}
						required
					/>
					<small className="red-font">This field is required</small>
					{/* Validation message for Title */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* ------Brief Decription-----  */}

				{/* Row Label section */}
				<h3>Row Label</h3>
				<p>Describe what each row in the dataset represents (if applicable)</p>
				{/* -------Row Label------  */}
				<Form.Group className="mb-3 rowLabel" controlId="formBasicEmail">
					{/* Label for Row Label */}
					<Form.Label>Row Label</Form.Label>
					<HelpButtonRowLabel />
					{/* Text field for Row Label */}
					<Form.Control
						type="text"
						placeholder="e.g. Crime, Person, Hospital"
						name="rowLabel"
						value={metaData.rowLabel}
						onChange={handleMetaDataChange}
					/>
				</Form.Group>
				{/* -------Row Label------  */}

				{/* Categories and Tags section */}
				<h3>Categories and Tags</h3>
				<p>Categorize your dataset to make it easier to find</p>
				{/* -------Category------  */}
				<Form.Group className="mb-3 categories" controlId="formBasicEmail">
					{/* Label for Category */}
					<Form.Label>Category</Form.Label>
					{/* Select option for Category */}
					<Form.Select
						aria-label="Default select example"
						name="category"
						onChange={handleTotalChange}
						value={totalData.category}
					>
						<option>-- No category --</option>
						<option value="Business">Business</option>
						<option value="Education">Education</option>
						<option value="Finance">Finance</option>
						<option value="Government">Government</option>
						<option value="Health">Health</option>
					</Form.Select>
				</Form.Group>
				{/* -------Category------  */}
				{/* -------Tags and Keywords------  */}
				<Form.Group className="mb-3 tagsKeywords" controlId="formBasicEmail">
					{/* Label for Tags / Keywords */}
					<Form.Label>
						Tags / Keywords <span className="text-danger">*</span>
						<HelpButtonTagsKeywords />
					</Form.Label>

					<>
						{/* Component for Tags / Keywords */}
						<InputGroup className="mb-3">
							<MyTagInput
								onhandleChangeTag={(text) => setTagData(text)}
								value={totalData.tags}
							/>
							<small className="red-font">
								<div>Tags must be unique</div>
								<div>This field is required</div>
							</small>

							{/* <p style={{ color: "red" }}>{requiredTag}</p> */}
						</InputGroup>
					</>
				</Form.Group>
				{/* -------Tags and Keywords------  */}

				{/* Licensing & Attribution section */}
				<h3>Licensing & Attribution</h3>
				{/* -------Public License Type------  */}
				<Form.Group
					className="mb-3 publicLicenseType"
					controlId="formBasicEmail"
				>
					{/* Label for License */}
					<Form.Label>Public License Type (if applicable)</Form.Label>
					{/* Select Option for License */}
					<Form.Select
						aria-label="Default select example"
						name="name"
						value={licenseData.name}
						onChange={handleLicenseChange}
					>
						<option>-- No License --</option>
						<option value="Canada Open Government Licence">
							Canada Open Government Licence
						</option>
						<option value="Creative Commons 1.0 Universal (Public Domain Dedication)">
							Creative Commons 1.0 Universal (Public Domain Dedication)
						</option>
						<option value="Creative Commons Attribution 3.0 Australia">
							Creative Commons Attribution 3.0 Australia
						</option>
						<option value="Creative Commons Attribution 3.0 IGO">
							Creative Commons Attribution 3.0 IGO
						</option>
						<option value="Creative Commons Attribution 3.0 New Zealand">
							Creative Commons Attribution 3.0 New Zealand
						</option>
						<option value="Creative Commons Attribution 3.0 Unported">
							Creative Commons Attribution 3.0 Unported
						</option>
						<option value="Creative Commons Attribution 4.0 International">
							Creative Commons Attribution 4.0 International
						</option>
						<option value="Creative Commons Attribution | No Derivative Works 3.0 Unported">
							Creative Commons Attribution | No Derivative Works 3.0 Unported
						</option>
						<option
							value="Creative Commons Attribution | NoDerivatives 4.0 International
							License"
						>
							Creative Commons Attribution | NoDerivatives 4.0 International
							License
						</option>
						<option value="Creative Commons Attribution | Noncommercial 3.0 New Zealand">
							Creative Commons Attribution | Noncommercial 3.0 New Zealand
						</option>
						<option value="Creative Commons Attribution | Noncommercial 3.0 Unported">
							Creative Commons Attribution | Noncommercial 3.0 Unported
						</option>
						<option
							value="Creative Commons Attribution | Noncommercial No Derivative Works
							3.0 IGO"
						>
							Creative Commons Attribution | Noncommercial No Derivative Works
							3.0 IGO
						</option>
						<option
							value="Creative Commons Attribution | Noncommercial No Derivative Works
							3.0 Unported"
						>
							Creative Commons Attribution | Noncommercial No Derivative Works
							3.0 Unported
						</option>
						<option
							value="Creative Commons Attribution | Noncommercial Share Alike 3.0 New
							Zealand"
						>
							Creative Commons Attribution | Noncommercial Share Alike 3.0 New
							Zealand
						</option>
						<option
							value="Creative Commons Attribution | Noncommercial | Share Alike 3.0
							Unported"
						>
							Creative Commons Attribution | Noncommercial | Share Alike 3.0
							Unported
						</option>
						<option value="Creative Commons Attribution Share Alike 3.0 Unported">
							Creative Commons Attribution Share Alike 3.0 Unported
						</option>
						<option value="Creative Commons Attribution | Share Alike 4.0 International">
							Creative Commons Attribution | Share Alike 4.0 International
						</option>
						<option value="Italian Open Data License 2.0">
							Italian Open Data License 2.0
						</option>
						<option value="National Institute of Standards and Technology Licensing">
							National Institute of Standards and Technology Licensing
						</option>
						<option value="New Brunswick Open Government Licence">
							New Brunswick Open Government Licence
						</option>
						<option value="Nova Scotia Open Government Licence">
							Nova Scotia Open Government Licence
						</option>
						<option value="Open Data Commons Attribution License">
							Open Data Commons Attribution License
						</option>
						<option value="Open Data Commons Open Database License">
							Open Data Commons Open Database License
						</option>
						<option value="Open Data Commons Public Domain Dedication and License">
							Open Data Commons Public Domain Dedication and License
						</option>
						<option value="Open Government Licence - Prince Edward Island">
							Open Government Licence - Prince Edward Island
						</option>
						<option value="Public Domain">Public Domain</option>
						<option value="Public Domain U.S. Government">
							Public Domain U.S. Government
						</option>
						<option value="See Terms of Use">See Terms of Use</option>
						<option value="UK Open Government Licence v3">
							UK Open Government Licence v3
						</option>
					</Form.Select>
				</Form.Group>
				{/* -------Public License Type------  */}
				{/* -------Date Provided By------  */}
				<Form.Group className="mb-3 dataProvidedBy" controlId="formBasicEmail">
					{/* Label for Data */}
					<Form.Label>Data Provided By</Form.Label>
					{/* Text field for Data */}
					<Form.Control
						type="text"
						placeholder="Individual or Organization"
						name="attribution"
						value={totalData.attribution}
						onChange={handleTotalChange}
					/>
				</Form.Group>
				{/* -------Data Provided By------  */}
				{/* -------Source Link------  */}
				<Form.Group className="mb-3 sourceLink" controlId="formBasicEmail">
					{/* Label for Source Link */}
					<Form.Label>Source Link(URL)</Form.Label>
					{/* Text field for Source Link */}
					<Form.Control
						type="text"
						placeholder="Enter web address"
						name="attributionLink"
						placeholder="https://www.hhs.gov"
						value={totalData.attributionLink}
						onChange={handleTotalChange}
						style={attributionLinkStyle}
					/>
				</Form.Group>
				{/* -------Source Link------  */}

				{/* Contact Email section */}
				<h3>Contact Email</h3>
				{/* -------Email Address------  */}
				<Form.Group className="mb-3 emailAddress" controlId="formBasicEmail">
					{/* Label for Email Address */}
					<Form.Label>Email Address</Form.Label>
					<br></br>
					{/* Description for Email Address */}
					<Form.Text className="text-muted">
						&nbsp; <FontAwesomeIcon icon={faLock} /> Private field. Only logged
						in users will see this value.
					</Form.Text>
					{/* Text field for Email Address */}
					<Form.Control
						type="email"
						placeholder="example@socrata.com"
						name="contactEmail"
						value={privateMetaData.contactEmail}
						onChange={handlePrivateMetaDataChange}
					/>
				</Form.Group>
				{/* -------Email Address------  */}

				{/* Attachments section */}
				<h3>Attachments</h3>
				{/* -------Add Attachments------  */}
				<Form.Group className="mb-3 attachments" controlId="formBasicEmail">
					{/* Description for Attachments */}
					<Form.Text className="text-muted">
						If you want to provide extra context to this dataset please{" "}
						<a href="#" target="_blank">
							Click Here
						</a>
						.
					</Form.Text>
					<br></br>
					{/* Component for File Upload */}
					<div className="addAttachmentButton">
						<FileUpload
							onhandleChange={(text) => {
								setFileData(text);
							}}
							value={fileData ? fileData : []}
							// value={[]}
						/>
					</div>
				</Form.Group>
				{/* -------Add Attachments------  */}

				{/* Common Core Section*/}
				{/* -------Publisher------  */}
				<Form.Group className="mb-3 publisher" controlId="formBasicEmail">
					{/* Label for Publisher */}
					<Form.Label>
						Publisher <span className="text-danger">*</span>
						<HelpButtonPublisher />
					</Form.Label>
					{/* Select option for Publisher */}
					<Form.Select
						aria-label="Default select example"
						name="Publisher"
						value={commonCore["Publisher"]}
						onChange={handleCommonCoreChange}
						required
					>
						<option value="">-- No Selection --</option>
						<option value="Department of Veterans Affairs">
							Department of Veterans Affairs
						</option>
					</Form.Select>
					<small className="red-font">This field is required</small>
					{/* Validation message for Publisher */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------Publisher------  */}
				{/* -------VA Organization------  */}
				<Form.Group className="mb-3 vaOrganization" controlId="formBasicEmail">
					{/* Label for VA Organization */}
					<Form.Label>
						VA Organization <span className="text-danger">*</span>
						<HelpButtonOrganization />
					</Form.Label>
					{/* Option select for VA Organization */}
					<Form.Select
						aria-label="Default select example"
						name="VA Organization"
						value={commonCore["VA Organization"]}
						onChange={handleCommonCoreChange}
						required
					>
						<option value="">-- No Selection --</option>
						<option value="Advisory Committee Management Office">
							Advisory Committee Management Office
						</option>
						<option value="Board of Veterans' Appeals">
							Board of Veterans' Appeals
						</option>
						<option value="Center for Faith-Based and Neighborhood Partnerships">
							Center for Faith-Based and Neighborhood Partnerships
						</option>
						<option value="Center for Minority Veterans">
							Center for Minority Veterans
						</option>
						<option value="Department of Veterans Affairs">
							Department of Veterans Affairs
						</option>
						<option value="Departmental Administration">
							Departmental Administration
						</option>
						<option value="Inspector General">Inspector General</option>
						<option value="National Cemetery Administration">
							National Cemetery Administration
						</option>
						<option value="Office of Accountability and Whistleblower Protection">
							Office of Accountability and Whistleblower Protection
						</option>
						<option value="Office of Acquisition and Logistics">
							Office of Acquisition and Logistics
						</option>
						<option value="Office of Congressional and Legislative Affairs">
							Office of Congressional and Legislative Affairs
						</option>
						<option value="Office of Employment Discrimination Complaint Adjudication">
							Office of Employment Discrimination Complaint Adjudication
						</option>
						<option value="Office of Enterprise Integration Office of General Counsel">
							Office of Enterprise Integration Office of General Counsel
						</option>
						<option value="Office of General Counsel">
							Office of General Counsel
						</option>
						<option value="Office of Human Resources and Administration">
							Office of Human Resources and Administration
						</option>
						<option value="Office of Information and Technology">
							Office of Information and Technology
						</option>
						<option value="Office of Management">Office of Management</option>
						<option value="Office of Security and Preparedness">
							Office of Security and Preparedness
						</option>
						<option value="Office of the Chief of Staff">
							Office of the Chief of Staff
						</option>
						<option value="Office of Public & Intergovernmental Affairs">
							Office of Public & Intergovernmental Affairs
						</option>
						<option value="Office of Regulation Policy and Management">
							Office of Regulation Policy and Management
						</option>
						<option value="Office of the Secretary">
							Office of the Secretary
						</option>
						<option value="Office of Small and Disadvantaged Business Utilization">
							Office of Small and Disadvantaged Business Utilization
						</option>
						<option value="Secretary's Center for Strategic Partnerships (SCSP)">
							Secretary's Center for Strategic Partnerships (SCSP)
						</option>
						<option value="Veterans Benefits Administration">
							Veterans Benefits Administration
						</option>
						<option value="Veterans Health Administration">
							Veterans Health Administration
						</option>
						<option value="Veterans Experience Office (VEO)">
							Veterans Experience Office (VEO)
						</option>
						<option value="Veterans Service Organization Liaison">
							Veterans Service Organization Liaison
						</option>
					</Form.Select>
					<small className="red-font">This field is required</small>
					{/* Validation message for VA Organization */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------VA Organization------  */}
				{/* -------Contact Name------  */}
				<Form.Group className="mb-3 contactName" controlId="formBasicEmail">
					{/* Label for Contact Name */}
					<Form.Label>
						Contact Name <span className="text-danger">*</span>
						<HelpButtonContactName />
					</Form.Label>
					{/* Text field for Contact Name */}
					<Form.Control
						type="text"
						placeholder="John Doe"
						name="Contact Name"
						value={commonCore["Contact Name"]}
						onChange={handleCommonCoreChange}
						required
					/>
					<small className="red-font">This field is required</small>
					{/* Validation for Contact Name */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------Contact Name------  */}
				{/* -------Contact Email------  */}
				<Form.Group className="mb-3 contactEmail" controlId="formBasicEmail">
					{/* Label for Custom Email */}
					<Form.Label>
						Contact Email <span className="text-danger">*</span>
						<HelpButtonContactEmail />
					</Form.Label>
					{/* Text field for Custom Email */}
					<Form.Control
						type="email"
						placeholder="example@socrata.com"
						name="Contact Email"
						value={commonCore["Contact Email"]}
						onChange={handleCommonCoreChange}
						required
					/>
					<small className="red-font">This field is required</small>
					{/* Validation for Custom Email */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------Contact Email------  */}
				{/* -------Bureau Code------  */}
				<Form.Group className="mb-3 bureauCode" controlId="formBasicEmail">
					{/* Label for Bureau Code */}
					<Form.Label>
						Bureau Code <span className="text-danger">*</span>
						<HelpButtonBureauCode />
					</Form.Label>
					{/* Select option for Bureau code */}
					<Form.Select
						aria-label="Default select example"
						name="Bureau Code"
						value={commonCore["Bureau Code"]}
						onChange={handleCommonCoreChange}
						required
					>
						<BureauCode />
					</Form.Select>
					<small className="red-font">This field is required</small>
					{/* Validation for Bureau code */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------Bureau Code------  */}
				{/* -------Program Code------  */}
				<Form.Group className="mb-3 programCode" controlId="formBasicEmail">
					{/* Label for Program Code */}
					<Form.Label>
						Program Code <span className="text-danger">*</span>
						<HelpButtonProgramCode />
					</Form.Label>
					{/* Select option for Program Code */}
					<Form.Select
						aria-label="Default select example"
						name="Program Code"
						value={commonCore["Program Code"]}
						onChange={handleCommonCoreChange}
						required
					>
						<ProgramCode />
					</Form.Select>
					<small className="red-font">This field is required</small>
					{/* Validation for Program Code */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------Program Code------  */}
				{/* -------Public Access Level------  */}
				<Form.Group
					className="mb-3 publicAccessLevel"
					controlId="formBasicEmail"
				>
					<Form.Label>
						{/* Label for Public Access Level */}
						Public Access Level <span className="text-danger">*</span>
						<HelpButtonPublicAccessLevel />
					</Form.Label>
					{/* Select option for Public Access Level */}
					<Form.Select
						aria-label="Default select example"
						name="Public Access Level"
						value={commonCore["Public Access Level"]}
						onChange={handleCommonCoreChange}
						required
					>
						<option value="">-- No Selection --</option>
						<option value="non-public">Non-Public</option>
						<option value="public">Public</option>
						<option value="restricted public">Restricted Public</option>
					</Form.Select>
					<small className="red-font">This field is required</small>
					{/* Validation for Public Access Level */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------Public Access Level------  */}
				{/* -------Access Level Comment------  */}
				<Form.Group
					className="mb-3 accessLevelComment"
					controlId="formBasicEmail"
				>
					{/* Label for Access Level Comment */}
					<Form.Label>Access Level Comment</Form.Label>
					{/* Text field for Access Level Comment */}
					<Form.Control
						type="text"
						name="Access Level Comment"
						placeholder="Enter comment about access level here"
						value={commonCore["Access Level Comment"]}
						onChange={handleCommonCoreChange}
					/>
				</Form.Group>
				{/* -------Access Level Comment------  */}
				{/* -------Geographic Coverage------  */}
				<Form.Group
					className="mb-3 geographicCoverage"
					controlId="formBasicEmail"
				>
					{/* Label for Geographic Coverage */}
					<Form.Label>Geographic Coverage</Form.Label>
					{/* Text field for Geographic Coverage */}
					<Form.Control
						type="text"
						name="Geographic Coverage"
						placeholder="Enter comment about geographic coverage here"
						value={commonCore["Geographic Coverage"]}
						onChange={handleCommonCoreChange}
					/>
				</Form.Group>
				{/* -------Grographic Coverage------  */}
				{/* -------Temporal Applicability------  */}
				<Form.Group
					className="mb-3 temporalApplicability"
					controlId="formBasicEmail"
				>
					{/* Label for Temporal Applicability */}
					<Form.Label>Temporal Applicability</Form.Label>
					{/* Text field for Temporal Applicability */}
					<Form.Control
						type="text"
						name="Temporal Applicability"
						placeholder="2000-01-15T00:45:00Z/P1W"
						value={commonCore["Temporal Applicability"]}
						onChange={handleCommonCoreChange}
						style={temporalStyle}
					/>
					<small className={temporalClassName}>
						This field's type should be
						2000-01-15T00:45:00Z/2010-01-15T00:06:00Z <br></br>
						Need help? Please <a href="mailto:jh@pinntechllc.com">
							email me
						</a>{" "}
						for assistance.
					</small>
				</Form.Group>
				{/* -------Temporal Applicability------  */}
				{/* -------Theme------  */}
				<Form.Group className="mb-3 theme" controlId="formBasicEmail">
					{/* Label for Theme */}
					<Form.Label>Theme</Form.Label>
					{/* Text field for Theme */}
					<Form.Control
						type="text"
						name="Theme"
						placeholder="vegetables, produce"
						value={commonCore["Theme"]}
						onChange={handleCommonCoreChange}
					/>
				</Form.Group>
				{/* -------Theme------  */}
				{/* -------Described By------  */}
				<Form.Group className="mb-3 describedBy" controlId="formBasicEmail">
					{/* Label for Described By */}
					<Form.Label>Described By(URL)</Form.Label>
					{/* Text field for Described By */}
					<Form.Control
						type="text"
						name="Described By"
						placeholder="https://www.hhs.gov"
						value={commonCore["Described By"]}
						onChange={handleCommonCoreChange}
						style={describedLinkStyle}
					/>
				</Form.Group>
				{/* -------Described By------  */}
				{/* -------Is Quality Data------  */}
				<Form.Group className="mb-3 isQualityData" controlId="formBasicEmail">
					{/* Label for Is Quality Data */}
					<Form.Label>Is Quality Data</Form.Label>
					{/* Select option for Quality Data */}
					<Form.Select
						aria-label="Default select example"
						name="Is Quality Data"
						value={commonCore["Is Quality Data"]}
						onChange={handleCommonCoreChange}
					>
						<option>-- No Selection --</option>
						<option value="true">True</option>
						<option value="false">False</option>
					</Form.Select>
				</Form.Group>
				{/* -------Is Quality Data------  */}
				{/* -------Update Frequency------  */}
				<Form.Group className="mb-3 updateFrequency" controlId="formBasicEmail">
					{/* Label for Update Frequency */}
					<Form.Label>Update Frequency</Form.Label>
					{/* Select option for Update Frequency */}
					<Form.Select
						aria-label="Default select example"
						name="Update Frequency"
						value={commonCore["Update Frequency"]}
						onChange={handleCommonCoreChange}
					>
						<UpdateFrequency />
					</Form.Select>
				</Form.Group>
				{/* -------Update Frequency------  */}
				{/* -------Language------  */}
				<Form.Group className="mb-3 language" controlId="formBasicEmail">
					{/* Label for Language */}
					<Form.Label>Language</Form.Label>
					{/* Text field for Language */}
					<Form.Control
						type="text"
						name="Language"
						value={commonCore["Language"]}
						placeholder="en-US"
						onChange={handleCommonCoreChange}
						style={languageStyle}
					/>
					<small className={languageClassName}>
						This field's type should be an array of string. The value type
						should be "en-US".<br></br>
						Need help? Please <a href="mailto:jh@pinntechllc.com">
							email me
						</a>{" "}
						for assistance.
					</small>
				</Form.Group>
				{/* -------Language------  */}
				{/* -------Primary IT Investment UII------  */}
				<Form.Group
					className="mb-3 primaryItInvestmentUi"
					controlId="formBasicEmail"
				>
					{/* Label for Primary IT */}
					<Form.Label>Primary IT Investment UII</Form.Label>
					{/* Text field for Primary IT */}
					<Form.Control
						type="text"
						name="Primary IT Investment UII"
						placeholder="023-000000001"
						value={commonCore["Primary IT Investment UII"]}
						onChange={handleCommonCoreChange}
					/>
				</Form.Group>
				{/* -------Primary IT Investment UII------  */}
				{/* -------System of Records------  */}
				<Form.Group className="mb-3 systemOfRecords" controlId="formBasicEmail">
					{/* Label for System of Records */}
					<Form.Label>System of Records(URL)</Form.Label>
					{/* Text field for System of Records */}
					<Form.Control
						type="text"
						name="System of Records"
						placeholder="https://www.hhs.gov"
						value={commonCore["System of Records"]}
						onChange={handleCommonCoreChange}
						style={systemLinkStyle}
					/>
				</Form.Group>
				{/* -------System of Records------  */}
				{/* -------Homepage------  */}
				<Form.Group className="mb-3 homepage" controlId="formBasicEmail">
					{/* Label for Homepage */}
					<Form.Label>Homepage(URL)</Form.Label>
					{/* Text field for Homepage */}
					<Form.Control
						type="text"
						name="Homepage"
						placeholder="https://www.hhs.gov"
						value={commonCore["Homepage"]}
						onChange={handleCommonCoreChange}
						style={homepageLinkStyle}
					/>
				</Form.Group>
				{/* -------Homepage------  */}
				{/* -------Issued------  */}
				<Form.Group className="mb-3 issued" controlId="formBasicEmail">
					{/* Label for Issued */}
					<Form.Label>Issued</Form.Label>
					{/* Text field for Issued */}
					<Form.Control
						type="text"
						name="Issued"
						placeholder="2001-01-15"
						value={commonCore["Issued"]}
						onChange={handleCommonCoreChange}
					/>
				</Form.Group>
				{/* -------Issued------  */}
				{/* -------References------  */}
				<Form.Group className="mb-3 references" controlId="formBasicEmail">
					{/* Label for References */}
					<Form.Label>References(URL)</Form.Label>
					{/* Text field for References */}
					<Form.Control
						type="text"
						placeholder="https://www.website.com, https://www.hhs.gov"
						name="References"
						value={commonCore["References"]}
						onChange={handleCommonCoreChange}
					/>
				</Form.Group>
				{/* -------References------  */}
				{/* -------License------  */}
				<Form.Group className="mb-3 license" controlId="formBasicEmail">
					{/* Label for License */}
					<Form.Label>
						License <span className="text-danger">*</span>
						<HelpButtonLicense />
					</Form.Label>
					{/* Select option for License */}
					<Form.Select
						aria-label="Default select example"
						name="License"
						value={commonCore["License"]}
						onChange={handleCommonCoreChange}
						required
					>
						<option value="">-- No Selection --</option>
						<option value="https://creativecommons.org/publicdomain/zero/1.0/">
							Creative Commons "Zero"
						</option>
					</Form.Select>
					<small className="red-font">This field is required</small>
					{/* Validation for License */}
					{/* <Form.Control.Feedback type="invalid">
						This field is required
					</Form.Control.Feedback> */}
				</Form.Group>
				{/* -------License------  */}
				{/* -------Collection------  */}
				<Form.Group className="mb-3 collection" controlId="formBasicEmail">
					{/* Label for Collection */}
					<Form.Label>Collection</Form.Label>
					{/* Text field for Collection */}
					<Form.Control
						type="text"
						name="Collection"
						placeholder="http://dx.doi.org/10.7927/H4PZ56R2"
						value={commonCore["Collection"]}
						onChange={handleCommonCoreChange}
					/>
				</Form.Group>
				{/* -------Collection------  */}

				{/* Secretary Priorities */}
				<h3>Secretary Priorities</h3>
				{/* -------Secretary Priorities------  */}
				<Form.Group
					className="mb-3 secretaryPriorities"
					controlId="formBasicEmail"
				>
					{/* Description for Secretary priorities */}
					<Form.Text className="text-muted">Secretary Priorities</Form.Text>
					{/* Select option for Secretary Priorities */}
					<Form.Select
						aria-label="Default select example"
						name="Secretary Priorities"
						value={secretaryPriorities["Secretary Priorities"]}
						onChange={handleSecretaryChange}
					>
						<option>-- No Selection --</option>
						<option value="Accountability">Accountability</option>
						<option value="Business Systems Transformation">
							Business Systems Transformation
						</option>
						<option value="Community Learning Centers">
							Community Learning Centers
						</option>
						<option value="Customer Service">Customer Service</option>
						<option value="Health Records">Health Records</option>
						<option value="Hiring and Vacancies">Hiring and Vacancies</option>
						<option value="MISSION Act Implementation">
							MISSION Act Implementation
						</option>
						<option value="Suicide Prevention">Suicide Prevention</option>
						<option value="Wait Times">Wait Times</option>
						<option value="Women's Health">Women's Health</option>
					</Form.Select>
				</Form.Group>
				{/* -------Secretary Priorities------  */}

				<div className="button-bottom">
					<hr></hr>
					{/* Cancel button */}
					<Button variant="secondary">Cancel</Button>
					{/* Save button */}
					<Button
						className="button-save"
						variant="primary"
						onClick={handleSubmit}
					>
						Save
					</Button>
				</div>
			</Form>
			<ToastContainer />
		</div>
	);
}

export default ShowForm;
