import React, { Component } from "react";
import "@pathofdev/react-tag-input/build/index.css";
import ReactTagInput from "@pathofdev/react-tag-input";

// This is the component for file upload filed
function FileUpload({ onhandleChange, value }) {
	// State that is used in file upload field
	const [tags, setTags] = React.useState([]);
	// Variable to store the uploaded file list
	const list = [];

	// Create a reference to the hidden file input element
	const hiddenFileInput = React.useRef(null);

	// Programmatically click the hidden file input element
	// when the Button component is clicked
	const handleClick = (event) => {
		hiddenFileInput.current.click();
	};
	// Call a function (passed as a prop from the parent component)
	// to handle the user-selected file
	const fileChange = (event) => {
		const fileUploaded = event.target.files[0];
		if (fileUploaded) {
			const newTags = [...tags, fileUploaded.name];
			setTags(newTags);
			onhandleChange(newTags);
		}
	};

	React.useEffect(() => {
		// console.log(value);
		setTags([...value]);
	}, [value]);
	const addList = (text) => {
		return [...list, text];
	};

	return (
		<div className="fileUpload">
			{/* Button to upload  */}
			{/* <Button onClick={handleClick}>Add Attachments</Button> */}
			{/* File input  */}
			<input
				type="file"
				ref={hiddenFileInput}
				onChange={fileChange}
				style={{ display: "none" }}
			/>
			{/* Show uploaded file  */}
			<ReactTagInput
				tags={tags}
				placeholder=" "
				onChange={(newTags) => {
					let temp = newTags[newTags.length - 1];
					let k = newTags.length - 1;
					for (let i = 0; i < k; i++) {
						if (newTags[i].includes(temp)) return;
					}
					setTags(newTags);
					onhandleChange(newTags);
				}}
			/>
		</div>
	);
}
export default FileUpload;
