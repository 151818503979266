import * as React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import ProgramCodes from "../../data/ProgramCodes.json";

// This is the component for tag/keyword input field
function ProgramCode() {
	return (
		<>
			<option>-- No License --</option>
			{ProgramCodes.map((programCode) => {
				return (
					<option key={programCode.ProgramCode} value={programCode.ProgramCode}>
						{programCode["Program Name"]}
					</option>
				);
			})}
		</>
	);
}
export default ProgramCode;
