import React from "react";
import "../HelpButtonCSS/HelpButtonPublisherCSS.css";

const HelpButtonPublish = () => {
	return (
		<div className="help11">
			<div className="question11">Ⓘ</div>

			<div className="popup11">
				<h3>Publisher</h3>
				<p>
					This is a container for a publisher object which groups together the
					fields: name and subOrganization. The subOrganization field can also
					contain a publisher object which allows one to describe an
					organization’s hierarchy. Where greater specificity is desired,
					include as many levels of publisher as is useful, in ascending order,
					using the below format.&nbsp;
					<a
						target="_blank"
						rel="noreferrer"
						href={"https://resources.data.gov/resources/dcat-us/#publisher"}
					>
						Click Here
					</a>
					&nbsp;to read more about the Publisher.
				</p>
				{/* <p>

				</p>
				<p>

				</p> */}
				{/* <br />
				<br />
				<div className="tell-me">
					<p>Tell me more!</p>
					<div className="popup2">
						<h4>Sub-levels</h4>
						<p className="sub-levels">
							<strong>a</strong> = you consistently reach this level
						</p>
						<p className="sub-levels">
							<strong>b</strong> = you reach this level most of the time
						</p>
						<p className="sub-levels">
							<strong>c</strong> = you sometimes reach this level
						</p>
						<p>
							<a href="#" target="_blank">
								Click here
							</a>{" "}
							for more detailed Computing &amp; IT level descriptions.
						</p>
						<p>
							<em>
								Level descriptions for individual units will be available soon.
							</em>
						</p>
					</div>
				</div> */}
			</div>
		</div>
	);
};

export default HelpButtonPublish;
